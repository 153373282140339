export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0x1A8B9E018e86Bec0f69439698d15099bB790002B";

export const artistId = 3539;
export const artistAddress = "0x8F9672ADC55F8f5f840E2aeD9338b8E8c11188a9";

export const projectName = "Portrait Day";
export const projectLink = "https://portraitday.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1718202600000;

export const artistName = "Aditya";
export const bio = [
  "Aditya is a contemporary digital artist who creates visually striking and thought-pro-voking digital animations that reflect on contemporary life, pop culture, and the human condition.",
  "Aditya's animations are characterized by their dynamic narratives, striking visual style and the use of a wide range of animation techniques.",
  "He blends traditional animation techniques with experimental styles, creating works that challenge the boundaries of animation and digital art.",
  "Through his art, Aditya explores the impact of contemporary culture on our everyday lives, offering a unique and fresh perspective on the world around us.",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@aditya";
export const artistTwitter = "https://x.com/@adityajainart";
export const artistInsta = "https://www.instagram.com/aditya.jainart";

export const interviewPath = "/interview/A-conversation-with-Aditya-8";
export const articlePath =
  "/article/Reimagining-Pop:-Warhol-Basquiat-and-Aditya's-Artistic-Parallels-9";
export const standard = "ERC-721";
export const mintedOn = "Manifold";
export const totalPieces = "9";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
